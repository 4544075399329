import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Avatar } from '../General/Avatar'
import SignOutContainer from '../../containers/SignOutContainer';
import { ChevronDown, ChevronUp } from 'lucide-react';

import {
  HomeOutlined,
  CustomerServiceOutlined,
  LoginOutlined
} from '@ant-design/icons';
import QuickLanguageSwitcher from '../QuickLanguageSwitcher';

const NewAvatarHeader = ({ userData, t, darkMode, onSelectDarkMode, onShowFeedback }) => {

  const dropdownDesktopRef = useRef(null);
  const [showProfileOptions, setShowProfileOptions] = useState(false);

  useEffect(() => {
    // Add event listener to close the popup when clicked outside
    const handleClickOutside = (event) => {
      if (dropdownDesktopRef.current && !dropdownDesktopRef.current.contains(event.target)) {
        dropdownDesktopRef.current.classList.remove('newshow');
        setShowProfileOptions(false);
        // Your code to handle closing the popup goes here
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  function onOpenTogglerDesktop(e) {
    e.stopPropagation();
    dropdownDesktopRef.current.classList.toggle('newshow');
    setShowProfileOptions(!showProfileOptions);
  }

  function showSupport() {
    window.HubSpotConversations.widget.open();
  }

  const handleFeedbackClick = (e) => {
    e.preventDefault();
    onShowFeedback();
  };

  function hideDropDown() {
    dropdownDesktopRef.current.classList.remove('newshow');
  }

  return (
    <nav className="navbar navbar-main navbar-height navbar-expand-lg theme-transparent">
      <div className="gap-3">
        <div id="nav" className="d-flex align-items-center">
          <div className="d-flex align-items-center">
            {!userData?.emailVerified &&
              <Link
                to="/signin"
                className="btn btn-outline-light btn-sm rounded-pill text-nowrap"
                style={{}}
                type="default"
              >
                {t('button.getStarted')}
              </Link>}
            <div className="dropdown">
              <button
                className="navbar-dropdown-account-wrapper"
                id="accountNavbarDropdown"
                onClick={onOpenTogglerDesktop}
              >
                {userData?.emailVerified ? (
                  <>
                    <Avatar
                      color='#222'
                      size={40}
                      initials={`${userData?.firstName?.charAt(0).toUpperCase() ?? ""} ${userData?.lastName?.charAt(0).toUpperCase() ?? ""}`}
                    />
                    <span className='avatar-name'>{`${userData?.firstName ?? ""} ${userData?.lastName ?? ""}`}</span>
                    <span
                      className='text-white'
                    >
                      {showProfileOptions ? (
                        <ChevronUp width={22} height={22}/>
                      ) : (
                        <ChevronDown width={22} height={22}/>
                      )}
                    </span>
                  </>
                ) : (
                  <span className="avatar flex-shrink-0 avatar-base avatar-circle">
                    <div className="avatar-initials bg-white">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        viewBox="0 0 16 16"
                        fill="none"
                        className="svg-icon rounded-0"
                      >
                        <g clipPath="url(#4f25a6d6acd8dd3fbe3e)">
                          <path
                            d="M8 0C3.58849 0 0 3.58849 0 8C0 12.4115 3.58849 16 8 16C12.4115 16 16 12.4115 16 8C16 3.58849 12.4115 0 8 0ZM8 2.85712C9.57119 2.85712 10.8571 4.14313 10.8571 5.71431C10.8571 7.2855 9.57119 8.57144 8 8.57144C6.42881 8.57144 5.14286 7.2855 5.14286 5.71431C5.14286 4.14313 6.42881 2.85712 8 2.85712V2.85712ZM5.71429 9.71431H10.2857C11.8459 9.71431 13.1199 10.9781 13.1406 12.5335C11.8852 13.9566 10.052 14.8571 8 14.8571C5.94803 14.8571 4.11482 13.9566 2.85937 12.5335C2.88006 10.9781 4.15412 9.71431 5.71429 9.71431V9.71431Z"
                            fill="#495057"
                          />
                        </g>
                        <defs>
                          <clipPath id="4f25a6d6acd8dd3fbe3e">
                            <rect width={16} height={16} fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </span>
                )}
                {/* <svg fill="#fff" width="12px" height="12px" viewBox="0 0 0.42 0.42" xmlns="http://www.w3.org/2000/svg" className="relative w-6 h-6 text-gray-500 group-hover:text-gray-600">
                  <path d="M0.348 0.222a0.017 0.017 0 0 1 0 -0.023L0.37 0.174A0.021 0.021 0 0 0 0.372 0.153L0.337 0.092A0.021 0.021 0 0 0 0.319 0.084L0.286 0.091A0.017 0.017 0 0 1 0.265 0.079L0.255 0.047A0.021 0.021 0 0 0 0.238 0.035h-0.07a0.017 0.017 0 0 0 -0.017 0.012L0.141 0.079A0.017 0.017 0 0 1 0.12 0.091L0.087 0.084A0.021 0.021 0 0 0 0.07 0.092L0.035 0.153a0.021 0.021 0 0 0 0.002 0.02l0.022 0.025a0.017 0.017 0 0 1 0 0.023L0.037 0.247A0.021 0.021 0 0 0 0.035 0.267L0.07 0.328a0.021 0.021 0 0 0 0.019 0.008l0.033 -0.007a0.017 0.017 0 0 1 0.02 0.012l0.01 0.032a0.017 0.017 0 0 0 0.017 0.012h0.07A0.021 0.021 0 0 0 0.257 0.374L0.267 0.342A0.017 0.017 0 0 1 0.288 0.33l0.033 0.007A0.021 0.021 0 0 0 0.339 0.328l0.035 -0.061A0.021 0.021 0 0 0 0.372 0.247ZM0.322 0.245l0.014 0.016 -0.022 0.039L0.293 0.296a0.052 0.052 0 0 0 -0.06 0.035L0.226 0.35H0.181L0.175 0.33a0.052 0.052 0 0 0 -0.06 -0.035L0.095 0.3 0.071 0.261 0.085 0.245a0.052 0.052 0 0 0 0 -0.07L0.071 0.159 0.094 0.12l0.02 0.004a0.052 0.052 0 0 0 0.06 -0.035L0.181 0.07h0.045l0.007 0.02a0.052 0.052 0 0 0 0.06 0.035L0.314 0.12 0.336 0.16 0.322 0.176a0.052 0.052 0 0 0 0 0.07M0.204 0.141a0.07 0.07 0 1 0 0.07 0.07 0.07 0.07 0 0 0 -0.07 -0.07m0 0.105a0.035 0.035 0 1 1 0.035 -0.035 0.035 0.035 0 0 1 -0.035 0.035" />
                </svg> */}
              </button>
              {userData ? (
                <div
                  className="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account"
                  aria-labelledby="accountNavbarDropdown"
                  ref={dropdownDesktopRef}
                >
                  <div className="dropdown-item fw-semi-bold">
                    <a
                      href="https://reok.lemonsqueezy.com/billing"
                      style={{ color: 'inherit' }}
                      target='_blank'
                    >
                      <span style={{ display: "inline-grid", paddingRight: "2px" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="24px" height="24px" viewBox="0 0 0.72 0.72">
                          <path d="M0.6 0.12H0.12c-0.033 0 -0.06 0.027 -0.06 0.06L0.06 0.54c0 0.033 0.027 0.06 0.06 0.06h0.48c0.033 0 0.06 -0.027 0.06 -0.06V0.18c0 -0.033 -0.027 -0.06 -0.06 -0.06m0 0.42H0.12v-0.18h0.48zm0 -0.3H0.12V0.18h0.48z" />
                        </svg>
                      </span>
                      <span>{t('header.nav.billing')}</span>
                    </a>
                  </div>
                  <div className="new-dropdown-divider order-5" />
                  <div className="order-5" onClick={hideDropDown}>
                    <a className="dropdown-item" onClick={handleFeedbackClick} style={{ cursor: "pointer" }}>
                      <CustomerServiceOutlined />
                      <span>{t('header.nav.supportContact')}</span>
                    </a>
                  </div>
                  <div className="new-dropdown-divider order-5" />
                  <div className="dropdown-item fw-semi-bold">
                    <SignOutContainer t={t} />
                  </div>
                </div>
              ) : (
                <div
                  className="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account"
                  aria-labelledby="accountNavbarDropdown"
                  ref={dropdownDesktopRef}
                >
                  <Link className="dropdown-item fw-semi-bold" to="/signin">
                    <LoginOutlined /><span style={{ marginLeft: "5px" }}>{t('header.nav.signIn')}</span>
                  </Link>
                  <div className="new-dropdown-divider order-5" />
                  <a className="dropdown-item fw-semi-bold" href="/signup">
                    <LoginOutlined /><span style={{ marginLeft: "5px" }}>{t('header.nav.signUp')}</span>
                  </a>
                  <div className="new-dropdown-divider order-5" />
                  <div className="order-5" onClick={hideDropDown}>
                    <a className="dropdown-item" onClick={handleFeedbackClick} style={{ cursor: "pointer" }}>
                      <CustomerServiceOutlined />
                      <span style={{ marginLeft: "5px" }}>{t('header.nav.supportContact')}</span>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="screen-mode-btn cursor-pointer" onClick={onSelectDarkMode}>
            {darkMode ? (
              <svg fill="#fff" width="18px" height="18px" viewBox="0 0 1.2 1.2" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <title>moon-solid</title>
                <path d="M0.73 0.668a0.302 0.302 0 0 1 -0.158 -0.557A0.343 0.343 0 0 0 0.487 0.1a0.35 0.35 0 0 0 0 0.7 0.346 0.346 0 0 0 0.273 -0.134 0.3 0.3 0 0 1 -0.03 0.002" />
                <path x="0" y="0" width="36" height="36" fillOpacity="0" d="M0 0H0.9V0.9H0V0z" />
              </svg>
            ) : (
              <svg width="18px" height="18px" viewBox="0 0 0.8 0.8" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_429_11039)">
                  <path cx="12" cy="12" r="4" stroke="#fff" strokeWidth="0.0625" strokeLinejoin="round" d="M0.4 0.3A0.1 0.1 0 0 1 0.3 0.4A0.1 0.1 0 0 1 0.2 0.3A0.1 0.1 0 0 1 0.4 0.3z" />
                  <path d="M0.5 0.3h0.025" stroke="#fff" strokeWidth="0.0625" strokeLinecap="round" />
                  <path d="M0.075 0.3h0.025" stroke="#fff" strokeWidth="0.0625" strokeLinecap="round" />
                  <path d="M0.3 0.5v0.025" stroke="#fff" strokeWidth="0.0625" strokeLinecap="round" />
                  <path d="M0.3 0.075v0.025" stroke="#fff" strokeWidth="0.0625" strokeLinecap="round" />
                  <path d="m0.441 0.441 0.018 0.018" stroke="#fff" strokeWidth="0.0625" strokeLinecap="round" />
                  <path d="m0.141 0.141 0.018 0.018" stroke="#fff" strokeWidth="0.0625" strokeLinecap="round" />
                  <path d="m0.159 0.441 -0.018 0.018" stroke="#fff" strokeWidth="0.0625" strokeLinecap="round" />
                  <path d="m0.459 0.141 -0.018 0.018" stroke="#fff" strokeWidth="0.0625" strokeLinecap="round" />
                </g>
                <defs>
                  <clipPath id="clip0_429_11039">
                    <path width="24" height="24" fill="#fff" d="M0 0H0.6V0.6H0V0z" />
                  </clipPath>
                </defs>
              </svg>
            )}
          </div>
          <div className="pl-2">
            <QuickLanguageSwitcher />
          </div>
        </div>
      </div>
    </nav>
  )
}

export default NewAvatarHeader
