import React, { useEffect } from "react"
import Editor from "./Editor"
import { currentModel } from "../lib/api"
import { useStore } from "../lib/states"
import ImageSize from "./ImageSize"
import Plugins from "./Plugins"
import { InteractiveSeg } from "./InteractiveSeg"
import SidePanel from "./SidePanel"
// import DiffusionProgress from "./DiffusionProgress"
import { MODEL_TYPE_DIFFUSERS_SDXL_INPAINT } from "../lib/const"
import { UPSCALE_MODEL, MAGIC_FILL_MODEL, REMOVE_OBJECTS_MODEL } from '../../../constants/model';
import { useToast } from "./ui/use-toast"
import { useTranslation } from 'react-i18next';

const Workspace = ({userData, firebase, choseModel, isMobile}) => {
  const [file, updateSettings] = useStore((state) => [
    state.file,
    state.updateSettings,
  ])

  const { toast } = useToast()

  const { t } = useTranslation();

  // useEffect(() => {
  //   const fetchCurrentModel = async () => {
  //     const model = await currentModel()
  //     updateSettings({ model })
  //   }
  //   fetchCurrentModel()
  // }, [])

  useEffect(() => {
    if (choseModel === MAGIC_FILL_MODEL) {
      toast({
        title: t('message.warning.aiMagicFill'),
      })
    } else if (choseModel === REMOVE_OBJECTS_MODEL) {
      toast({
        title: t('message.warning.aiFurnitureRemoval'),
      })
    } else if (choseModel === UPSCALE_MODEL) {
      toast({
        title: t('message.warning.upscale'),
      })
    } 
  },[
    choseModel
  ])

  return (
    <>
      <div className="flex gap-3 absolute top-[68px] left-[24px] items-center">
        {/* <Plugins /> */}
        <ImageSize choseModel={choseModel}/>
      </div>
      {/* <InteractiveSeg /> */}
      {/* <DiffusionProgress /> */}
      {/* <SidePanel /> */}
      {file ? <Editor file={file} userData={userData} firebase={firebase} choseModel={choseModel} isMobile={isMobile}/> : <></>}
    </>
  )
}

export default Workspace
