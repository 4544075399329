import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';
import { styled } from '@mui/material/styles';
import PhotoPipeLine from '../PhotoPipeLine';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Focus } from 'lucide-react';

const icons = {
  All: <svg fill="currentColor" width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3,11h7a1,1,0,0,0,1-1V3a1,1,0,0,0-1-1H3A1,1,0,0,0,2,3v7A1,1,0,0,0,3,11ZM4,4H9V9H4ZM22,3a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1ZM20,9H15V4h5ZM2,21a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1H3a1,1,0,0,0-1,1Zm2-6H9v5H4Zm18,6V14a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7A1,1,0,0,0,22,21Zm-2-1H15V15h5Z" /></svg>,
  Trending: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-flame"><path d="M8.5 14.5A2.5 2.5 0 0 0 11 12c0-1.38-.5-2-1-3-1.072-2.143-.224-4.054 2-6 .5 2.5 2 4.9 4 6.5 2 1.6 3 3.5 3 5.5a7 7 0 1 1-14 0c0-1.153.433-2.294 1-3a2.5 2.5 0 0 0 2.5 2.5z" /></svg>,
  ForCreator: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-thumbs-up"><path d="M7 10v12" /><path d="M15 5.88 14 10h5.83a2 2 0 0 1 1.92 2.56l-2.33 8A2 2 0 0 1 17.5 22H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h2.76a2 2 0 0 0 1.79-1.11L12 2a3.13 3.13 0 0 1 3 3.88Z" /></svg>,
  ForBusiness: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-handshake"><path d="m11 17 2 2a1 1 0 1 0 3-3" /><path d="m14 14 2.5 2.5a1 1 0 1 0 3-3l-3.88-3.88a3 3 0 0 0-4.24 0l-.88.88a1 1 0 1 1-3-3l2.81-2.81a5.79 5.79 0 0 1 7.06-.87l.47.28a2 2 0 0 0 1.42.25L21 4" /><path d="m21 3 1 11h-2" /><path d="M3 3 2 14l6.5 6.5a1 1 0 1 0 3-3" /><path d="M3 4h8" /></svg>,
  CreateWithAI: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-wand-sparkles"><path d="m21.64 3.64-1.28-1.28a1.21 1.21 0 0 0-1.72 0L2.36 18.64a1.21 1.21 0 0 0 0 1.72l1.28 1.28a1.2 1.2 0 0 0 1.72 0L21.64 5.36a1.2 1.2 0 0 0 0-1.72" /><path d="m14 7 3 3" /><path d="M5 6v4" /><path d="M19 14v4" /><path d="M10 2v2" /><path d="M7 8H3" /><path d="M21 16h-4" /><path d="M11 3H9" /></svg>,
  // Headshot: <Focus/>,
};

const categories = [
  { name: 'All', icon: icons.All },
  { name: 'Trending', icon: icons.Trending },
  // { name: 'Headshot', icon: icons.Headshot },
  { name: 'For creator', icon: icons.ForCreator },
  { name: 'For business', icon: icons.ForBusiness },
  { name: 'Magic AI tools', icon: icons.CreateWithAI },
];

const magicAITools = [
  { title: 'Magic Fixer', icon: icons.MagicFixer, image: 'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/admin%2Ffiles%2Fyoutube-thumbnail.png?alt=media&token=440d71ac-36f5-4f8d-b47d-1fb34868ff66', description: 'Fix your images magically.', isHot: false },
  { title: 'Magic Eraser', icon: icons.MagicEraser, image: 'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/admin%2Ffiles%2Fyoutube-thumbnail.png?alt=media&token=440d71ac-36f5-4f8d-b47d-1fb34868ff66', description: 'Erase unwanted elements effortlessly.', isHot: false },
  { title: 'Magic Upscaler', icon: icons.MagicUpscaler, image: 'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/admin%2Ffiles%2Fyoutube-thumbnail.png?alt=media&token=440d71ac-36f5-4f8d-b47d-1fb34868ff66', description: 'Enhance image resolution seamlessly.', isHot: false }
];

const photographerStyle = 'photographer_style';
const photographerPose = 'photographer_pose';
const photographerRemodeling = 'photographer_remodeling';

const modes = {
  pro: 'Pro',
  basic: 'Basic',
};

const MAX_WIDTH = 768;

const ageItems = [];
for (let age = 18; age <= 100; age++) {
  ageItems.push({
    value: age,
    label: age,
  });
}

const StyledTabs = styled(({ darkMode, ...props }) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ darkMode }) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    width: "100%",
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    // backgroundColor: "#fb5183",
    backgroundImage: "linear-gradient(115deg, #4fcf70, #fad648, #a767e5, #12bcfe, #44ce7b)"
  },
}));

const StyledTab = styled(({ darkMode, ...props }) => (
  <Tab disableRipple {...props} />
))(({ darkMode }) => ({
  textTransform: 'none',
  fontWeight: 600,
  fontSize: 16,
  fontFamily: 'inherit',
  letterSpacing: "-0.01em",
  backgroundColor: 'transparent',
  color: darkMode ? '#5d5d5d' : '#15293c80',
  '&.Mui-selected': {
    color: darkMode ? 'white' : 'black',
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: "-0.01em"
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const HomePage = ({
  firebase,
  setUser,
  darkMode,
  userData,
  pack,
}) => {
  const [isActiveDeskMenu, setIsActiveDeskMenu] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [photoPipeLine, setPhotoPipeLine] = useState('');
  const [showPhotoPipeLine, setShowPhotoPipeLine] = useState(false);
  const [trendingPacks, setTrendingPacks] = useState([]);
  const [forCreatorPacks, setForCreatorPacks] = useState([]);
  const [forBusinessPacks, setForBusinessPacks] = useState([]);
  const [createWithAIPacks, setCreateWithAIPacks] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    setIsLoadingData(true);
    firebase.getPhotoPacksOnLanding().then(querySnapshot => {
      const sortedPacks = querySnapshot.docs.map(doc => doc.data()).sort((a, b) => {
        return (b.isHot ? 1 : 0) - (a.isHot ? 1 : 0) || (new Date(a.createdAt) - new Date(b.createdAt)); // Sort by isHot and then by createdAt
      });
      setTrendingPacks(sortedPacks.filter(pack => pack.category.includes('trending')).slice(0, 5));
      setForCreatorPacks(sortedPacks.filter(pack => pack.category.includes('for_creator')).slice(0, 5));
      setForBusinessPacks(sortedPacks.filter(pack => pack.category.includes('for_business')).slice(0, 5));
      setCreateWithAIPacks(sortedPacks.filter(pack => pack.category.includes('create_with_ai')).slice(0, 5));

      if (pack && sortedPacks.some(sortedPack => sortedPack.id === pack)) {
        const matchingPack = sortedPacks.find(sortedPack => sortedPack.id === pack);
        setPhotoPipeLine(matchingPack);
        setShowPhotoPipeLine(true);
      }
    }).finally(() => {
      setIsLoadingData(false);
    });
  }, [pack]);

  useEffect(() => {
    if (window.innerWidth <= 980) {
      setIsMobile(true);
      setIsActiveDeskMenu(false);
    }

    const handleResize = () => {
      if (window.innerWidth <= 980) {
        setIsMobile(true);
        setIsActiveDeskMenu(false);
      } else {
        setIsMobile(false);
        setIsActiveDeskMenu(true);
      }
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const hideMenuBar = () => {
    setIsActiveDeskMenu(!isActiveDeskMenu);
  };

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const TabLabel = ({ icon, label }) => (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      {icon}
      <span>{label}</span>
    </div>
  );

  const openPhotoPipeLine = (e, item) => {
    e.preventDefault();
    setPhotoPipeLine(item);
    setShowPhotoPipeLine(true);
  }

  const closePhotoPipeLine = () => {
    setShowPhotoPipeLine(false);
  }

  return (
    <>
      <div
        className={`home-area ${darkMode ? 'dark-bg' : 'light-bg'}`}
        style={!isActiveDeskMenu ? { left: '0', width: '100%' } : { transition: 'width 0.35s ease', width: 'calc(100% - 250px)' }}
      >
        <div className="home-page-container mx-auto">
          {!isMobile ? (
            <>
              <div className="flex align-items-center mb-4">
                <span style={{ color: '#888888' }} className={`${darkMode ? 'cursor-pointer-dark' : 'cursor-pointer-light'}`} onClick={hideMenuBar}>
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    className="bi bi-layout-sidebar-inset"
                  >
                    <path d="M21 3a1.5 1.5 0 0 1 1.5 1.5v15a1.5 1.5 0 0 1 -1.5 1.5H3a1.5 1.5 0 0 1 -1.5 -1.5V4.5a1.5 1.5 0 0 1 1.5 -1.5zM3 1.5a3 3 0 0 0 -3 3v15a3 3 0 0 0 3 3h18a3 3 0 0 0 3 -3V4.5a3 3 0 0 0 -3 -3z" />
                    <path d="M4.5 6a1.5 1.5 0 0 1 1.5 -1.5h3a1.5 1.5 0 0 1 1.5 1.5v12a1.5 1.5 0 0 1 -1.5 1.5H6a1.5 1.5 0 0 1 -1.5 -1.5z" />
                  </svg>
                </span>
                <h1 className={`mb-0 pl-4 font-bold ${darkMode ? 'text-white' : 'text-black'}`} style={{ fontSize: "19px", fontWeight: "600" }}>Explore AI Studio</h1>
                {/* <h3 className={`mb-0 pl-4 ${darkMode ? 'text-white' : 'text-black'}`}>{t('app.menu.studioMode')}</h3> */}
              </div>
            </>
          ) : (
            <div className="flex align-items-center mt-7">
              <h1 className={`font-bold mb-2 ${darkMode ? 'text-white' : 'text-black'}`} style={{ fontSize: "19px", fontWeight: "600" }}>Explore AI Studio</h1>
            </div>
          )}
          <div className="space-x-4 mb-4">
            <Box sx={{ borderBottom: 1, borderColor: darkMode ? '#373737' : '#f1f3f4' }}>
              <StyledTabs
                darkMode={darkMode}
                value={activeTab}
                onChange={handleChangeTab}
                aria-label="main tab"
                variant="scrollable"
              >
                {categories.map((category, index) => (
                  <StyledTab
                    key={index}
                    darkMode={darkMode}
                    label={<TabLabel icon={category.icon} label={category.name} />}
                  />
                ))}
              </StyledTabs>
            </Box>
            <CustomTabPanel value={activeTab} index={0}>
              <div className={`p-4 ${darkMode ? 'text-white' : 'text-black'}`}>
                <p className={`mt-2 mb-2 bold`}>Trending</p>
                <div className="rnftg-grid rnftg-gap-4 rnftg-grid-flow-row rnftg-grid-cols-2 md:rnftg-grid-cols-4 lg:rnftg-grid-cols-5 xl:rnftg-grid-cols-5">
                  {trendingPacks.map((item, key) => (
                    <div key={key} onClick={(e) => openPhotoPipeLine(e, item)} className={`rounded-lg shadow-md overflow-hidden transition-colors duration-200 w-full ${darkMode ? 'dark-bg-home' : ''} home-item`}>
                      <div className="relative aspect-w-16 aspect-h-9">
                        <img src={item.image} alt={item.title} className="w-full h-full object-cover" />
                        {item.isHot && (
                          <div className="absolute top-2 right-2 bg-pink-500 text-white text-xs font-bold px-2 py-1 rounded-full" style={{ marginTop: "-12px" }}>
                            Hot
                          </div>
                        )}
                      </div>
                      <div className="blur-photo-pipe-line">
                        <p className="text-white text-sm px-4 text-center">Start with <br /> 3 easy steps →</p>
                      </div>
                      <div className="p-4">
                        <h3 className="text-sm font-bold mb-1">{item.title}</h3>
                        <p className="font-size-sm text-gray-600 dark:text-gray-300">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <p className={`mt-6 mb-2 bold`}>For creator</p>
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 xl:grid-cols-5 lg:grid-cols-5 gap-4">
                  {forCreatorPacks.map((item, key) => (
                    <div key={key} onClick={(e) => openPhotoPipeLine(e, item)} className={`rounded-lg shadow-md overflow-hidden transition-colors duration-200 w-full ${darkMode ? 'dark-bg-home' : ''} } home-item`}>
                      <div className="relative aspect-w-16 aspect-h-9">
                        <img src={item.image} alt={item.title} className="w-full h-full object-cover" />
                        {item.isHot && (
                          <div className="absolute top-2 right-2 bg-pink-500 text-white text-xs font-bold px-2 py-1 rounded-full" style={{ marginTop: "-12px" }}>
                            Hot
                          </div>
                        )}
                      </div>
                      <div className="blur-photo-pipe-line">
                        <p className="text-white text-sm px-4 text-center">Start with <br /> 3 easy steps →</p>
                      </div>
                      <div className="p-4">
                        <h3 className="text-sm font-bold mb-1">{item.title}</h3>
                        <p className="font-size-sm text-gray-600 dark:text-gray-300">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <p className={`mt-6 mb-2 bold`}>For business</p>
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 xl:grid-cols-5 lg:grid-cols-5 gap-4">
                  {forBusinessPacks.map((item, key) => (
                    <div key={key} onClick={(e) => openPhotoPipeLine(e, item)} className={`rounded-lg shadow-md overflow-hidden transition-colors duration-200 w-full ${darkMode ? 'dark-bg-home' : ''} } home-item`}>
                      <div className="relative aspect-w-16 aspect-h-9">
                        <img src={item.image} alt={item.title} className="w-full h-full object-cover" />
                        {item.isHot && (
                          <div className="absolute top-2 right-2 bg-pink-500 text-white text-xs font-bold px-2 py-1 rounded-full" style={{ marginTop: "-12px" }}>
                            Hot
                          </div>
                        )}
                      </div>
                      <div className="blur-photo-pipe-line">
                        <p className="text-white text-sm px-4 text-center">Start with <br /> 3 easy steps →</p>
                      </div>
                      <div className="p-4">
                        <h3 className="text-sm font-bold mb-1">{item.title}</h3>
                        <p className="font-size-sm text-gray-600 dark:text-gray-300">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
                {/* <p className={`mt-6 mb-2 bold`}>Magic AI tools</p>
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 xl:grid-cols-5 lg:grid-cols-5 gap-4">
                  {magicAITools.map((item, key) => (
                    <div key={key} onClick={(e) => openPhotoPipeLine(e, item)} className={`rounded-lg shadow-md overflow-hidden transition-colors duration-200 w-full ${darkMode ? 'dark-bg-home' : ''} } home-item`}>
                      <div className="relative aspect-w-16 aspect-h-9">
                        <img src={item.image} alt={item.title} className="w-full h-full object-cover" />
                        {item.isHot && (
                          <div className="absolute top-2 right-2 bg-pink-500 text-white text-xs font-bold px-2 py-1 rounded-full" style={{marginTop: "-12px"}}>
                            Hot
                          </div>
                        )}
                      </div>
                      <div className="p-4">
                        <h3 className="text-sm font-bold mb-1">{item.title}</h3>
                        <p className="font-size-sm text-gray-600 dark:text-gray-300">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div> */}
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={activeTab} index={1}>
              <div className={`p-4 ${darkMode ? 'text-white' : 'text-black'}`}>
                <p className={`mt-2 mb-2 bold`}>Trending</p>
                <div className="rnftg-grid rnftg-gap-4 rnftg-grid-flow-row rnftg-grid-cols-2 md:rnftg-grid-cols-4 lg:rnftg-grid-cols-5 xl:rnftg-grid-cols-5">
                  {trendingPacks.map((item, key) => (
                    <div key={key} onClick={(e) => openPhotoPipeLine(e, item)} className={`rounded-lg shadow-md overflow-hidden transition-colors duration-200 w-full ${darkMode ? 'dark-bg-home' : ''} home-item`}>
                      <div className="relative aspect-w-16 aspect-h-9">
                        <img src={item.image} alt={item.title} className="w-full h-full object-cover" />
                        {item.isHot && (
                          <div className="absolute top-2 right-2 bg-pink-500 text-white text-xs font-bold px-2 py-1 rounded-full" style={{ marginTop: "-12px" }}>
                            Hot
                          </div>
                        )}
                      </div>
                      <div className="blur-photo-pipe-line">
                        <p className="text-white text-sm px-4 text-center">Select to start with <br /> 3 easy steps →</p>
                      </div>
                      <div className="p-4">
                        <h3 className="text-sm font-bold mb-1">{item.title}</h3>
                        <p className="font-size-sm text-gray-600 dark:text-gray-300">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={activeTab} index={2}>
              <div className={`p-4 ${darkMode ? 'text-white' : 'text-black'}`}>
                <p className={`mt-2 mb-2 bold`}>For creator</p>
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 xl:grid-cols-5 lg:grid-cols-5 gap-4">
                  {forCreatorPacks.map((item, key) => (
                    <div key={key} onClick={(e) => openPhotoPipeLine(e, item)} className={`rounded-lg shadow-md overflow-hidden transition-colors duration-200 w-full ${darkMode ? 'dark-bg-home' : ''} } home-item`}>
                      <div className="relative aspect-w-16 aspect-h-9">
                        <img src={item.image} alt={item.title} className="w-full h-full object-cover" />
                        {item.isHot && (
                          <div className="absolute top-2 right-2 bg-pink-500 text-white text-xs font-bold px-2 py-1 rounded-full" style={{ marginTop: "-12px" }}>
                            Hot
                          </div>
                        )}
                      </div>
                      <div className="blur-photo-pipe-line">
                        <p className="text-white text-sm px-4 text-center">Select to start with <br /> 3 easy steps →</p>
                      </div>
                      <div className="p-4">
                        <h3 className="text-sm font-bold mb-1">{item.title}</h3>
                        <p className="font-size-sm text-gray-600 dark:text-gray-300">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={activeTab} index={3}>
              <div className={`p-4 ${darkMode ? 'text-white' : 'text-black'}`}>
                <p className={`mt-2 mb-2 bold`}>For business</p>
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 xl:grid-cols-5 lg:grid-cols-5 gap-4">
                  {forBusinessPacks.map((item, key) => (
                    <div key={key} onClick={(e) => openPhotoPipeLine(e, item)} className={`rounded-lg shadow-md overflow-hidden transition-colors duration-200 w-full ${darkMode ? 'dark-bg-home' : ''} } home-item`}>
                      <div className="relative aspect-w-16 aspect-h-9">
                        <img src={item.image} alt={item.title} className="w-full h-full object-cover" />
                        {item.isHot && (
                          <div className="absolute top-2 right-2 bg-pink-500 text-white text-xs font-bold px-2 py-1 rounded-full" style={{ marginTop: "-12px" }}>
                            Hot
                          </div>
                        )}
                      </div>
                      <div className="blur-photo-pipe-line">
                        <p className="text-white text-sm px-4 text-center">Select to start with <br /> 3 easy steps →</p>
                      </div>
                      <div className="p-4">
                        <h3 className="text-sm font-bold mb-1">{item.title}</h3>
                        <p className="font-size-sm text-gray-600 dark:text-gray-300">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </CustomTabPanel>
          </div>
        </div>
      </div>
      {showPhotoPipeLine && <PhotoPipeLine onClose={closePhotoPipeLine} isMobile={isMobile} firebase={firebase} setUser={setUser} darkMode={darkMode} userData={userData} t={t} photoPack={photoPipeLine} />}
    </>
  );
};

export default HomePage;
