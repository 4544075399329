import React, { useEffect, useState } from 'react';
import { HomeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';

import { Gradient } from '../AdminHeader/Gradient.js';

const gradient = new Gradient();

const Terms = () => {
  const [currentLanguage, setCurrentLanguage] = useState('');

  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    gradient.initGradient('#canvas-gradient');
    window.scrollTo(0, 0);

    return () => {};
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const view = urlParams.get('view');

    if (view) {
      const element = document.getElementById(view);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }

    return () => {};
  }, []);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <>
      <main className="bg-black">
        <div className="landing-cover" data-controller="gradient-animation">
          <div className="container">
            <div className="d-flex flex-column align-items-center">
              <h1 className="display-1 fw-semi-bold text-white text-center mb-4" style={{ zIndex: 99 }}>
                {t('terms.title')}
              </h1>
              <h3 className="mb-6 text-white text-center" style={{ zIndex: 99 }}>
                Last update and effective date: 11 October 2024
              </h3>
              <Link
                to={currentLanguage === 'en' ? '/' : `/${currentLanguage}/`}
                className="btn btn-soft-light btn-sm rounded-pill tag text-nowrap"
              >
                <HomeOutlined />
                {t('button.backHome')}
              </Link>
            </div>
          </div>
          <canvas id="canvas-gradient" data-transition-in />
          <div id="canvas-overlay" />
        </div>
      </main>
      <section className="text-black">
        <div>
          <div className="container mx-auto p-6">
            <div className="mb-4" />
            <div className="text-start" style={{ cursor: 'pointer' }}>
              <ul className="text-lg list-disc mx-auto">
                {/* <li className="mb-2 bold" onClick={() => document.getElementById('last-update').scrollIntoView({ behavior: 'smooth' })}>
                  <div id="last-update">Last update and effective date: 9 October 2024</div>
                </li> */}
                <li className="mb-2 bold" onClick={() => document.getElementById('tos').scrollIntoView({ behavior: 'smooth' })}>
                  <div>Terms of Service</div>
                </li>
                <li className="mb-2 bold" onClick={() => document.getElementById('privacy-policy').scrollIntoView({ behavior: 'smooth' })}>
                  <div>Privacy Policy</div>
                </li>
                <li className="mb-2 bold" onClick={() => document.getElementById('cookies-policy').scrollIntoView({ behavior: 'smooth' })}>
                  <div>Cookies Policy</div>
                </li>
              </ul>
            </div>
            <h2 className="mt-6 text-2xl font-bold mb-2" id="tos">
              Terms of Service
            </h2>
            <p className="mb-4">
              Welcome! Thank you for your interest in{' '}
              <a style={{ color: '#15c', textDecoration: 'underline' }} href="https://reok.pro">
                Reok.pro
              </a>{' '}
              ( “Reok.pro,” “we,” or “us”) and our website at{' '}
              <a style={{ color: '#15c', textDecoration: 'underline' }} href="https://reok.pro">
                www.reok.pro
              </a>
              , along with our related websites, hosted applications, mobile or other downloadable applications, and other services provided
              by us (collectively, the “Service”). These Terms of Service are a legally binding contract between you and{' '}
              <a style={{ color: '#15c', textDecoration: 'underline' }} href="https://reok.pro">
                Reok.pro
              </a>{' '}
              regarding your use of the Service.
            </p>
            <p className="mb-4 bold">
              PLEASE READ THESE TERMS OF USE (“AGREEMENT” OR “TERMS OF USE”) CAREFULLY BEFORE USING THE SERVICES OFFERED BY REOK.PRO. THIS
              AGREEMENT SETS FORTH THE LEGALLY BINDING TERMS AND CONDITIONS FOR YOUR USE OF THE REOK WEBSITE AND ALL RELATED SERVICES,
              WITHOUT LIMITATION, ANY FEATURES, CONTENT, WEBSITES OR APPLICATIONS OFFERED FROM TIME TO TIME BY REOK IN CONNECTION THEREWITH
              (COLLECTIVELY “SERVICE(S)”). BY USING THE SERVICES IN ANY MANNER, YOU AGREE TO BE BOUND BY THIS AGREEMENT.
            </p>
            <h3 className="font-bold mb-2 mt-4 text-lg">1. Acceptance of Terms of Use</h3>
            <p className="mb-4">
              The Service is offered subject to acceptance without modification of all of these Terms of Use and all other operating rules,
              policies and procedures that may be published from time to time in connection with the Services by Reok.pro. In addition, some
              services offered through the Service may be subject to additional terms and conditions promulgated by Reok.pro from time to
              time; your use of such services is subject to those additional terms and conditions, which are incorporated into these Terms
              of Use by this reference. Reok.pro may, in its sole discretion, refuse to offer the Service to any person or entity and change
              its eligibility criteria at any time. This provision is void where prohibited by law and the right to access the Service is
              revoked in such jurisdictions.
            </p>
            <h3 className="font-bold mb-2 mt-4 text-lg">2. Rules and Conduct</h3>
            <p className="mb-4">
              By using the REOK app, you agree that the Service is intended solely for the purpose of creating AI models of yourself or
              other individuals for whom you have obtained explicit consent. You acknowledge and agree that when creating AI models of other
              individuals, you must have their express permission to use their photos and to create, train, and generate AI-generated images
              of them.
            </p>
            <p className="mb-4">
              As a condition of use, you promise not to use the Service for any purpose that is prohibited by the Terms of Service. By way
              of example, and not as a limitation, you shall not (and shall not permit any third party to):
            </p>
            <p className="pl-4 mb-2">
              ⚬ Take any action (including making use of the Site, any Assets, or our models or derivatives of our models) that:
            </p>
            <ul className="pl-8 mb-4 list-disc list-inside">
              <li>
                ▪ Interfere or attempt to interfere with the proper working of the Service or any activities conducted on the Service.
              </li>
              <li>▪ Bypass any measures REOK may use to prevent or restrict access to the Service (or parts thereof).</li>
              <li>
                ▪ Use any method to extract data from the Services, including web scraping, web harvesting, or web data extraction methods,
                other than as permitted through an allowable API.
              </li>
              <li>
                ▪ Reverse assemble, reverse compile, decompile, translate or otherwise attempt to discover the source code or underlying
                components of models, algorithms, and systems of the Services that are not open (except to the extent such restrictions are
                contrary to applicable law).
              </li>
              <li>
                ▪ Reproduce, duplicate, copy, sell, resell or exploit any portion of the Site, use of the Site, or access to the Site or any
                contact on the Site, without our express written permission.
              </li>
            </ul>
            <p className="mb-4 bold">You agree to not use the Service for the purpose of generating nudes or pornography.</p>
            <p className="mb-4 bold">
              By using the Service and uploading any content, you expressly acknowledge and agree that you will not upload, post, generate
              or share any photographs or content depicting minors (individuals under the age of 18).
            </p>
            <p className="mb-4 bold">
              You further agree that, in compliance with applicable laws and regulations, we reserve the right to monitor and review any
              uploaded any generated content, and if we identify any content featuring minors, we will immediately remove such content and
              report any instances of potential child exploitation, endangerment, or abuse to the appropriate law enforcement authorities in
              your respective jurisdiction. By using our platform, you consent to such monitoring, review, and reporting, and you understand
              that you may be subject to legal repercussions if you violate these terms.
            </p>
            <p>Further, you shall not (directly or indirectly):</p>
            <ul className="pl-4 mb-4 list-disc list-inside">
              <li>
                (i) Take any action that imposes or may impose an unreasonable or disproportionately large load on REOK’s (or its third
                party providers’) infrastructure.
              </li>
              <li>
                (ii) Interfere or attempt to interfere with the proper working of the Service or any activities conducted on the Service.
              </li>
              <li>(iii) Bypass any measures REOK may use to prevent or restrict access to the Service (or parts thereof).</li>
              <li>
                (iv) Use any method to extract data from the Services, including web scraping, web harvesting, or web data extraction
                methods, other than as permitted through an allowable API.
              </li>
              <li>
                (v) Reverse assemble, reverse compile, decompile, translate or otherwise attempt to discover the source code or underlying
                components of models, algorithms, and systems of the Services that are not open (except to the extent such restrictions are
                contrary to applicable law).
              </li>
              <li>
                (vi) Reproduce, duplicate, copy, sell, resell or exploit any portion of the Site, use of the Site, or access to the Site or
                any contact on the Site, without our express written permission.
              </li>
            </ul>
            <h3 className="font-bold mb-2 mt-4 text-lg">3. DMCA and Takedowns Policy</h3>
            <p className="mb-4">
              REOK utilizes artificial intelligence systems to produce the Assets. Such Assets may be unintentionally similar to copyright
              protected material or trademarks held by others. We respect rights holders internationally and we ask our users to do the
              same. If you believe your copyright or trademark is being infringed by the Service, please write to help@reok.pro and we will
              process and investigate your request and take appropriate actions under the Digital Millennium Copyright Act and other
              applicable intellectual property laws with respect to any alleged or actual infringement.
            </p>
            <h3 className="font-bold mb-2 mt-4 text-lg">4. Modification of Terms of Use</h3>
            <p className="mb-4">
              At its sole discretion, REOK may modify or replace any of the Terms of Use, or change, suspend, or discontinue the Service
              (including without limitation, the availability of any feature, database, or content) at any time by posting a notice on the
              REOK websites or Service or by sending you an email. REOK may also impose limits on certain features and services or restrict
              your access to parts or all of the Service without notice or liability. It is your responsibility to check the Terms of Use
              periodically for changes. Your continued use of the Service following the posting of any changes to the Terms of Use
              constitutes acceptance of those changes.{' '}
            </p>
            <h3 className="font-bold mb-2 mt-4 text-lg">5. Trademarks and Patents</h3>
            <p className="mb-4">
              REOK logos, marks and designations are trademarks or registered trademarks of Reok.pro. All other trademarks mentioned in this
              website are the property of their respective owners. The trademarks and logos displayed on this website may not be used
              without the prior written consent of Reok.pro or their respective owners. Portions, features and/or functionality of
              Reok.pro’s products may be protected under Reok.pro patent applications or patents.
            </p>
            <h3 className="font-bold mb-2 mt-4 text-lg">6. Licensing Terms</h3>
            <p className="mb-4">
              Subject to your compliance with this Agreement, the conditions herein, and any limitations applicable to REOK or by law:
            </p>
            <ul className="pl-4 mb-4 list-disc list-inside">
              <li>
                (i) You are granted a non-exclusive, limited, non-transferable, non-sublicensable, non-assignable, freely revocable license
                to access and use the Service for business or personal use.
              </li>
              <li>(ii) You own all Assets you create with the Services.</li>
              <li>
                (iii) We hereby assign to you all rights, title, and interest in and to such Assets for your personal or commercial use.
              </li>
            </ul>
            <p className="mb-4">
              By using the Services, you grant to REOK, its successors, and assigns a perpetual, worldwide, non-exclusive, sublicensable,
              no-charge, royalty-free, irrevocable copyright license to use, copy, reproduce, process, adapt, modify, publish, transmit,
              prepare Derivative Works of, publicly display, publicly perform, sublicense, and/or distribute text prompts and images you
              input into the Services, or Assets produced by the Service at your direction. This license authorizes REOK to make the Assets
              available generally and to use such Assets as needed to provide, maintain, promote and improve the Services, as well as to
              comply with applicable law and enforce our policies. You agree that this license is provided with no compensation paid to you
              by REOK for your submission or creation of Assets, as the use of the Services by you is hereby agreed as being sufficient
              compensation for the grant of rights herein. You also grant each other user of the Service a worldwide, non-exclusive,
              royalty-free license to access your publicly available Assets through the Service, and to use those Assets (including to
              reproduce, distribute, modify, display, and perform it) only as enabled by a feature of the Service. The license to REOK
              survives termination of this Agreement by any party, for any reason.
            </p>
            <h3 className="font-bold mb-2 mt-4 text-lg">7. Fees and Payments</h3>
            <p className="mb-4">
              You agree that REOK provides you immediate access to a digital content as soon as you complete your purchase, without waiting
              the 14-day withdrawal period. Therefore, you expressly waive your right to withdraw from this purchase.{' '}
            </p>
            <p className="mb-4">
              REOK offers a free and paid Service. You can learn more about our paid subscription offering here. You can sign up for a
              monthly subscription, payable in U.S. dollars, that will automatically renew on a monthly basis. You can stop using the
              Service and cancel your subscription at any time through the website or by emailing us at help@reok.pro. If you cancel your
              subscription, you will not receive a refund or credit for any amounts that have already been billed or paid. REOK reserves the
              right to change its prices at any time. If you are on a subscription plan, changes to pricing will not apply until your next
              renewal.{' '}
            </p>
            <p className="mb-4">
              Unless otherwise stated, your subscription fees (“Fees”) do not include federal, state, local, and foreign taxes, duties, and
              other similar assessments (“Taxes”). You are responsible for all Taxes associated with your purchase and we may invoice you
              for such Taxes. You agree to timely pay such Taxes and provide us with documentation showing the payment or additional
              evidence that we may reasonably require. If any amount of your Fees are past due, we may suspend your access to the Services
              after we provide you written notice of late payment. You may not create more than one account to benefit from the Free tier of
              our Services. If we believe you are not using the Free tier in good faith, we may charge you standard fees or stop providing
              access to the Services.
            </p>
            <h3 className="font-bold mb-2 mt-4 text-lg">8. Termination</h3>
            <p className="mb-4">
              REOK may terminate your access to all or any part of the Service at any time if you fail to comply with these Terms of Use,
              which may result in the forfeiture and destruction of all information associated with your account. Further, either party may
              terminate the Services for any reason and at any time upon written notice. If you wish to terminate your account, you may do
              so by following the instructions on the Service. Any fees paid hereunder are non-refundable. Upon any termination, all rights
              and licenses granted to you in this Agreement shall immediately terminate, but all provisions hereof which by their nature
              should survive termination shall survive termination, including, without limitation, warranty disclaimers, indemnity and
              limitations of liability.
            </p>
            <h3 className="font-bold mb-2 mt-4 text-lg">9. Indemnification</h3>
            <p className="mb-4">
              You shall defend, indemnify, and hold harmless REOK, its affiliates, and each of its and its affiliates' employees,
              contractors, directors, suppliers, and representatives from all liabilities, losses, claims, and expenses, including
              reasonable attorneys’ fees, that arise from or relate to:
            </p>
            <ul className="pl-4 mb-4 list-disc list-inside">
              <li>(i) Your use or misuse of, or access to, the Service. </li>
              <li>(ii) Your violation of the Terms of Service or any applicable law, contract, policy, regulation, or other obligation.</li>
            </ul>
            <p className="mb-4">
              REOK reserves the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you,
              in which event you will assist and cooperate with REOK in connection therewith.
            </p>
            <h3 className="font-bold mb-2 mt-4 text-lg">10. Limitation of Liability</h3>
            <p className="mb-4">
              IN NO EVENT SHALL REOK OR ITS DIRECTORS, EMPLOYEES, AGENTS, PARTNERS, SUPPLIERS, OR CONTENT PROVIDERS BE LIABLE UNDER
              CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE, OR ANY OTHER LEGAL OR EQUITABLE THEORY WITH RESPECT TO THE SERVICE:
            </p>
            <ul className="pl-4 mb-4 list-disc list-inside">
              <li>
                (I) FOR ANY LOST PROFITS, DATA LOSS, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR SPECIAL, INDIRECT, INCIDENTAL,
                PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER, OR SUBSTITUTE GOODS OR SERVICES.
              </li>
              <li>(II) FOR YOUR RELIANCE ON THE SERVICE.</li>
              <li>
                (III) FOR ANY DIRECT DAMAGES IN EXCESS (IN THE AGGREGATE) OF THE FEES PAID BY YOU FOR THE SERVICE OR, IF GREATER, $500.
              </li>
            </ul>
            <p className="mb-4">
              SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS AND
              EXCLUSIONS MAY NOT APPLY TO YOU.
            </p>
            <h3 className="font-bold mb-2 mt-4 text-lg">11. Disclaimer</h3>
            <p className="mb-4">
              ALL USE OF THE SERVICE AND ANY CONTENT IS UNDERTAKEN ENTIRELY AT YOUR OWN RISK. THE SERVICE (INCLUDING, WITHOUT LIMITATION,
              THE REOK WEB APP AND ANY CONTENT) IS PROVIDED “AS IS” AND “AS AVAILABLE” AND IS WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
              IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A
              PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY
              DISCLAIMED. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY
              TO YOU.
            </p>
            <h3 className="font-bold mb-2 mt-4 text-lg">12. Age Requirements</h3>
            <p className="mb-4 bold">
              By accessing the Services, you confirm that you’re at least 18 years old and meet the minimum age of digital consent in your
              country. If you are not old enough to consent to our Terms of Use in your country, your parent or guardian must agree to this
              Agreement on your behalf.
            </p>
            <p className="mb-4">
              Please ask your parent or guardian to read these terms with you. If you’re a parent or legal guardian, and you allow your
              teenager to use the Services, then these terms also apply to you and you’re responsible for your teenager’s activity on the
              Services. No assurances are made as to the suitability of the Assets for you.
            </p>
            <h3 className="font-bold mb-2 mt-4 text-lg">13. Prohibited Uses</h3>
            <p className="mb-4">
              You are fully responsible for your activities while using the Site, including any content, information or other materials you
              post or upload to the Site, and you bear all risks associated with the use of the Site. By agreeing to these Terms, you agree
              to comply with all applicable federal, state, and local laws and regulations in connection with your use of the Site. You also
              agree not to use the Site to engage in any prohibited conduct or to assist any other person or entity in engaging in any
              prohibited conduct.
            </p>
            <p className="mb-4">We reserve the right (but not the obligation) in our sole discretion to:</p>
            <ul className="pl-4 mb-4 list-disc list-inside">
              <li>1. Monitor the Site for violations of these Terms</li>
              <li>
                2. Take appropriate legal action against anyone who uses or accesses the Site in a manner that we believe violates the law
                or these Terms, including, without limitation, reporting such user to law enforcement authorities.
              </li>
              <li>
                3. Deny access to the Site or any features of the Site to anyone who violates these Terms or who we believe interferes with
                the ability of others to enjoy our Site or infringes the rights of others.
              </li>
              <li>
                4. Otherwise manage the Site in a manner designed to protect our rights and property and to facilitate the proper
                functioning of the Site.
              </li>
            </ul>
            <p className="mb-2">
              You are prohibited from using the Site for the commission of harmful or illegal activities. Accordingly, you may not, or
              assist any other person to:
            </p>
            <ul className="pl-4 mb-4 list-disc list-inside">
              <li>⚬ Violate these Terms or other policies and terms posted on, or otherwise applicable to, the Site.</li>
              <li>⚬ Copy or adapt the Site’s software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.</li>
              <li>⚬ Violate the security measures of the Site.</li>
              <li>
                ⚬ Upload any material, program, or software that contains any virus, worm, spyware, Trojan horse or other program or code
                designed to interrupt, destroy or limit the functionality of the Site.
              </li>
              <li>
                ⚬ Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any
                automated system, including, without limitation, any spider, robot, cheat utility, scraper, offline reader, or any data
                mining or similar data gathering extraction tools to access the Site, or use or launch any unauthorized script or other
                software.
              </li>
              <li>⚬ Interfere with, disable, vandalise or disrupt the Site or servers or networks connected to the Site.</li>
              <li>
                ⚬ Hack into, penetrate, disable, or otherwise circumvent the security measures of the Site or servers or networks connected
                to the Site.
              </li>
              <li>⚬ Impersonate another person or falsely represent an affiliation with any organisation or institution.</li>
              <li>
                ⚬ Send email to the addresses linked or made available on the Site (including in these Terms) to harass, annoy, intimidate,
                or threaten any of our employees or agents.
              </li>
              <li>
                ⚬ Use the Site in any way that violates any applicable national, federal, state, local or international law or regulation.
              </li>
              <li>⚬ Attempt to do any of the above.</li>
            </ul>
            <p className="mb-4">
              We have implemented the procedures described in the Digital Millennium Copyright Act of 1998 (“DMCA”), 17 U.S.C. § 512,
              regarding the reporting of alleged copyright infringement and the removal of or disabling access to the infringing material.
              If you have a good faith belief that copyrighted material on the Site is being used in a way that infringes the copyright over
              which you are authorized to act, you may make a Notice of Infringing Material.
            </p>
            <p className="mb-4">
              Before serving a Notice of Infringing Material, you may wish to contact a lawyer to better understand your rights and
              obligations under the DMCA and other applicable laws. For example, if your Notice fails to comply with all requirements of
              section 512(c)(3), your Notice may not be effective.
            </p>
            <h3 className="font-bold mb-2 mt-4 text-lg">14. Links to Other Sites</h3>
            <p className="mb-4">
              Our Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that
              these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites.
              We have no control over, and assume no responsibility for the content, privacy policies, or practices of any third-party sites
              or services.
            </p>
            <h2 className="mt-8 text-2xl font-bold mb-2" id="privacy-policy">
              Privacy Policy
            </h2>
            <h3 className="font-bold mb-4 mt-4">Basic Information about Data Protection</h3>
            <table
              style={{
                borderCollapse: 'separate',
                borderSpacing: 0,
                width: '100%',
                border: '1px solid #ccc',
                borderRadius: '10px',
                overflow: 'hidden',
                backgroundColor: 'white',
                marginBottom: '20px',
              }}
              border={1}
            >
              <tbody>
                {/* <tr>
                  <th
                    style={{
                      backgroundColor: '#f9f9f9',
                      textAlign: 'left',
                      padding: '10px',
                      borderBottom: '1px solid #ccc',
                      borderRight: '1px solid #ccc',
                    }}
                  >
                    Controller
                  </th>
                  <td
                    style={{
                      padding: '10px',
                      borderBottom: '1px solid #ccc',
                      borderRight: '1px solid #ccc',
                    }}
                  >
                    Reok.pro
                    <br />
                    Email address: help@reok.pro
                  </td>
                </tr> */}
                <tr>
                  <th
                    style={{
                      backgroundColor: '#f9f9f9',
                      textAlign: 'left',
                      padding: '10px',
                      borderBottom: '1px solid #ccc',
                      borderRight: '1px solid #ccc',
                    }}
                  >
                    Website
                  </th>
                  <td
                    style={{
                      padding: '10px',
                      borderBottom: '1px solid #ccc',
                      borderRight: '1px solid #ccc',
                    }}
                  >
                    <a style={{ color: '#15c', textDecoration: 'underline' }} href="https://reok.pro">
                      https://reok.pro{' '}
                    </a>
                    or
                    <a style={{ color: '#15c', textDecoration: 'underline' }} href="https://reok.pro">
                      {' '}
                      www.reok.pro
                    </a>
                  </td>
                </tr>
                <tr>
                  <th
                    style={{
                      backgroundColor: '#f9f9f9',
                      textAlign: 'left',
                      padding: '10px',
                      borderBottom: '1px solid #ccc',
                      borderRight: '1px solid #ccc',
                    }}
                  >
                    Purposes and legal basis
                  </th>
                  <td
                    style={{
                      padding: '10px',
                      borderBottom: '1px solid #ccc',
                      borderRight: '1px solid #ccc',
                    }}
                  >
                    <ul>
                      <li>Providing you with and managing our Services (Performance of contractual relationship)</li>
                      <li>To improve Services, conduct surveys and prevent fraud and abuse (Legitimate interest)</li>
                      <li>To send you commercial information about our Services (Legitimate interest or consent, as applicable)</li>
                      <li>
                        To answer queries, requests suggestions and complaints (Performance of contractual relationship, legitimate interest
                        or consent, as applicable)
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th
                    style={{
                      backgroundColor: '#f9f9f9',
                      textAlign: 'left',
                      padding: '10px',
                      borderBottom: '1px solid #ccc',
                      borderRight: '1px solid #ccc',
                    }}
                  >
                    Recipients
                  </th>
                  <td
                    style={{
                      padding: '10px',
                      borderBottom: '1px solid #ccc',
                      borderRight: '1px solid #ccc',
                    }}
                  >
                    We will share your personal data with service providers who help or support us, with public administrations or those
                    with whom we have a legal obligation. International transfers of user data may be made, always with sufficient
                    guarantees to comply with the provisions of the applicable regulations.
                  </td>
                </tr>
                <tr>
                  <th
                    style={{
                      backgroundColor: '#f9f9f9',
                      textAlign: 'left',
                      padding: '10px',
                      borderBottom: '1px solid #ccc',
                      borderRight: '1px solid #ccc',
                    }}
                  >
                    International transfers of user data
                  </th>
                  <td
                    style={{
                      padding: '10px',
                      borderBottom: '1px solid #ccc',
                      borderRight: '1px solid #ccc',
                    }}
                  >
                    May be made, always with sufficient guarantees to comply with the provisions of the applicable regulations.
                  </td>
                </tr>
                <tr>
                  <th
                    style={{
                      backgroundColor: '#f9f9f9',
                      textAlign: 'left',
                      padding: '10px',
                      borderBottom: '1px solid #ccc',
                      borderRight: '1px solid #ccc',
                    }}
                  >
                    Rights of the data subject
                  </th>
                  <td
                    style={{
                      padding: '10px',
                      borderBottom: '1px solid #ccc',
                      borderRight: '1px solid #ccc',
                    }}
                  >
                    You have the right to access, rectify, erase, object, request limitation or portability or not be subject to automated
                    individualized decisions of your personal data, as we explain in detail below. You can direct your request to
                    help@reok.pro. You can also request the assistance of the data protection authority of the country you are based on
                    through its website.
                  </td>
                </tr>
                <tr>
                  <th
                    style={{
                      backgroundColor: '#f9f9f9',
                      textAlign: 'left',
                      padding: '10px',
                      borderBottom: '1px solid #ccc',
                      borderRight: '1px solid #ccc',
                    }}
                  >
                    Additional information
                  </th>
                  <td
                    style={{
                      padding: '10px',
                      borderBottom: '1px solid #ccc',
                      borderRight: '1px solid #ccc',
                    }}
                  >
                    For more information, we suggest that you read the full version of this Privacy Policy below. You can also consult our{' '}
                    <a style={{ color: '#15c', textDecoration: 'underline' }} href="/legal">
                      Terms of Service
                    </a>
                    .
                  </td>
                </tr>
              </tbody>
            </table>
            <p className="mb-4">
              Reok.pro operates the https://reok.pro website, which provides the SERVICE. Reok.pro takes data privacy very seriously. This
              Privacy Policy explains how we collect, use and manage your personal data when you ("User" or "you") browse our website
              https://reok.pro, including any of its subdomains or sections ("Website") or when you use our services through the Website
              ("Services"). REOK will process personal data in accordance with applicable data protection regulations.
            </p>
            <h3 className="font-bold mb-2 mt-4 text-lg">1. What data we process and what are the purposes</h3>
            <p className="pl-4 mb-4">
              1.1 During the registration process and the provision of the Services, REOK may process personal data provided by Users,
              including:
            </p>
            <ul className="pl-8 mb-4 list-disc list-inside gap-2">
              <li>
                1.1.1. <span className="bold">Identification data</span> which may vary depending on the selected authentication method.
              </li>
              <li className="mt-2">
                1.1.2. <span className="bold">Economic and transactional information</span> (e.g. your payment details, information about your subscription to our
                Services, data provided in the scope of the provision of Services, etc).
              </li>
            </ul>
            <p className="pl-4 mb-4">1.2 These personal data are used by REOK for the following purposes:</p>
            <ul className="pl-8 mb-4 list-disc list-inside gap-2">
              <li>
                1.2.1. <span className="bold">Providing Services and Managing the Contractual Relationship</span>: REOK may use the personal data described to provide the Services and carry out the maintenance and management of the
                contractual relationship, including the sending of notifications necessary for the proper provision of the Services. In this
                case, the legal basis for the processing of the described data is the performance of the contractual relationship between
                the User and REOK for the provision of the Services.
              </li>
              <li className="mt-2">
                1.2.2. <span className="bold">Managing and Improving Services</span>: REOK may also process personal data to manage and improve the Services, including the possibility of submitting
                alternatives or modifying the Services based on the use, responding to suggestions, conducting surveys and satisfaction
                analysis, as well as preventing fraud and abuse. The legal basis for the processing of the data described for this purpose
                will be the legitimate interest, since we understand that it is also beneficial for you because, on one hand, the purpose is
                to improve the User’s experience and offer higher quality Services and, on the other, performing the necessary checks to
                detect and prevent possible frauds or fraudulent uses allows us to implement measures to protect you against attempted fraud
                by third parties or misuse of our Services.
              </li>
              <li className="mt-2">
                1.2.3. <span className="bold">Sending Commercial Information and News</span>: Occasionally, REOK may send commercial information and news to Users, where applicable, in relation to the contracted
                Services, as well as information regarding promotional activities carried out in relation to them. If you do not wish to
                receive certain commercial communications from us, you can click on the "unsubscribe" link in the corresponding email or
                communicate it to us via help@reok.pro. The legal basis for the processing of personal data for this purpose is REOK’s
                legitimate interest in informing Users about news and promotional actions related to the Services, unless Users inform REOK
                that they do not wish to receive commercial communications. The legal basis may also be the consent of the User, in the
                event that the User has given its consent to REOK to receive commercial communications.
              </li>
              <li className="mt-2">
                1.2.4. <span className="bold">Compliance with Legal Obligations</span>: REOK may process the data described to comply with the regulations that are applicable in each case and to respond to
                requests of the public authorities, in which case, the legal basis for the processing of the data will be the fulfillment of
                the legal obligation applicable to the case.
              </li>
            </ul>
            <p className="pl-4 mb-4">
              1.3 REOK may obtain personal data from users of the Website through REOK’s e-mail address or REOK’s team members’ contact
              addresses (including through social media) for the following purposes:
            </p>
            <ul className="pl-8 mb-4 list-disc list-inside gap-2">
              <li>
                1.3.1. <span className="bold">Responding to Queries, Requests, Suggestions, or Complaints</span>: To respond to queries, requests, suggestions or complaints. The personal data of users of the Website (for example,
                contact details or first and last name) received by REOK through the means enabled for this purpose, will be used
                exclusively to respond to queries, requests, suggestions or complaints sent by users who access and browse the Website.
              </li>
            </ul>
            <h3 className="font-bold mb-2 mt-4 text-lg">2. How long we keep your personal data</h3>
            <p className="pl-4 mb-4">
              2.1 On a general basis, personal data will be kept for the time necessary to fulfill the purpose for which they were collected
              and, afterwards, for the periods established by the applicable regulations to comply with legal obligations. In particular:
            </p>
            <ul className="pl-8 mb-4 list-disc list-inside gap-2">
              <li>
                2.1.1. <span className="bold">Performance of the Contractual Relationship</span>: When the legal basis for the processing of your personal data is the performance of the contractual relationship, we
                will process your data for the duration of such contractual relationship.
              </li>
              <li className="mt-2">
                2.1.2. <span className="bold">Responding to Queries, Requests, Suggestions, or Complaints</span>: The data processed to respond to queries, requests, suggestions or complaints, will be kept for as long as necessary
                to respond to such queries, requests, suggestions or complaints.
              </li>
              <li className="mt-2">
                2.1.3. <span className="bold">Sending Commercial Communications</span>: The data processed to send you commercial communications will be processed until you request their cancellation in
                the receipt of said commercial communications.
              </li>
              <li className="mt-2">
                2.1.4. <span className="bold">Managing and Improving Services</span>: The information obtained to manage and improve the Services, including the possibility of conducting surveys and
                satisfaction analysis, as well as preventing fraud and abuse, will be treated punctually during the time in which we proceed
                to carry out a specific quality action or survey or until we anonymize your browsing data.
              </li>
            </ul>
            <p className="mb-4">
              After the end of the retention periods, the data will be blocked for the period of time necessary to meet any type of
              liability, as required by the applicable regulations depending on the case (e.g. until the limitation of the liability that
              may arise from the regulations on data protection).
            </p>

            <h2 className="mt-8 text-2xl font-bold mb-2" id="cookies-policy">
              Cookies Policy
            </h2>
            <p className="mb-4">
              At Reok.pro (“Reok.pro,” “we,” or “us”), we are committed to enhancing your experience on our website www.reok.pro (the
              “Website”) by utilizing cookies and similar technologies. This Cookies Policy explains what cookies are, how we use them, the
              types of cookies we employ, and your choices regarding their use.
            </p>
            <h3 className="font-bold mb-2 mt-4 text-lg">1. What Are Cookies?</h3>
            <p className="mb-4">
              Cookies are small text files that are stored on your device (computer, smartphone, tablet) when you visit a website. They help
              websites remember your actions and preferences over time, providing a smoother and more personalized browsing experience.
            </p>
            <h3 className="font-bold mb-2 mt-4 text-lg">2. How We Use Cookies</h3>
            <p className="mb-2">We use cookies to:</p>
            <ul className="pl-4 mb-4 list-disc list-inside gap-2" style={{ textDecoration: 'disc' }}>
              <li>
                <span className="bold">Enhance User Experience</span>: Remember your preferences and settings to provide a personalized
                experience.
              </li>
              <li>
                <span className="bold">Analyze Website Performance</span>: Understand how users interact with our Website to improve its
                functionality and content.
              </li>
              <li>
                <span className="bold">Provide Functionalities</span>: Enable essential features and services, such as login sessions and
                shopping carts.
              </li>
              <li>
                <span className="bold">Deliver Relevant Advertising</span>: Show you advertisements that are more relevant to your interests.
              </li>
            </ul>
            <h3 className="font-bold mb-2 mt-4 text-lg">3. Types of Cookies We Use</h3>
            <p className="mb-4">We categorize cookies based on their purpose and functionality:</p>
            <p className="mb-4 bold">a. Strictly Necessary Cookies</p>
            <p className="pl-4 mb-4">
              These cookies are essential for the operation of our Website. They enable core functionalities such as security, network
              management, and accessibility. Without these cookies, certain services you have requested cannot be provided.
            </p>
            <p className="pl-4 mb-2 bold">Examples:</p>
            <ul className="pl-8 mb-4 list-disc list-inside" style={{ listStyle: 'disc' }}>
              <li><span className="bold">Session Cookies</span>: Temporary cookies that remain in your browser until you leave the Website.</li>
              <li><span className="bold">Security Cookies</span>: Help detect and prevent security threats.</li>
            </ul>

            <p className="mb-4 bold">b. Performance Cookies</p>
            <p className="pl-4 mb-4">
              Performance cookies collect information about how visitors use our Website, such as which pages are visited most often and if
              they receive error messages from web pages. These cookies do not collect information that identifies a visitor. All
              information these cookies collect is aggregated and therefore anonymous.
            </p>
            <p className="pl-4 mb-2 bold">Examples:</p>
            <ul className="pl-8 mb-4 list-disc list-inside" style={{ listStyle: 'disc' }}>
              <li><span className="bold">Analytics Cookies</span>: Tools like Google Analytics to monitor and analyze Website performance.</li>
            </ul>

            <p className="mb-4 bold">c. Functionality Cookies</p>
            <p className="pl-4 mb-4">
              Functional cookies allow our Website to remember choices you make (such as your username, language, or the region you are in)
              and provide enhanced, more personalized features. These cookies can also be used to provide services you have requested, such
              as watching a video or commenting on a blog.
            </p>
            <p className="pl-4 mb-2 bold">Examples:</p>
            <ul className="pl-8 mb-4 list-disc list-inside" style={{ listStyle: 'disc' }}>
              <li><span className="bold">Preference Cookies</span>: Remember your settings and preferences.</li>
              <li><span className="bold">Social Media Cookies</span>: Enable sharing of content on social media platforms.</li>
            </ul>

            <p className="mb-4 bold">d. Targeting/Advertising Cookies</p>
            <p className="pl-4 mb-4">
              Targeting cookies are used to deliver advertisements more relevant to you and your interests. They are also used to limit the
              number of times you see an advertisement as well as help measure the effectiveness of advertising campaigns.
            </p>
            <p className="pl-4 mb-2 bold">Examples:</p>
            <ul className="pl-8 mb-4 list-disc list-inside" style={{ listStyle: 'disc' }}>
              <li><span className="bold">Behavioral Advertising Cookies</span>: Track your browsing habits to show tailored ads.</li>
              <li><span className="bold">Retargeting Cookies</span>: Display ads based on your previous interactions with our Website.</li>
            </ul>
            <h3 className="font-bold mb-2 mt-4 text-lg">4. Third-Party Cookies</h3>
            <p className="mb-4">
              We may allow third-party service providers to place cookies on your device to perform services such as analytics, advertising,
              and social media integration. These third parties may use cookies to collect information about your online activities over
              time and across different websites when you use our Services.
            </p>
            <p className="pl-4 mb-2 bold">Examples of Third-Party Services:</p>
            <ul className="pl-8 mb-4 list-disc list-inside" style={{ listStyle: 'disc' }}>
              <li>
                <span className="bold">Google Analytics</span>: For analyzing Website traffic and performance.
              </li>
              <li>
                <span className="bold">Advertising Networks</span>: To deliver personalized ads.
              </li>
            </ul>
            <h3 className="font-bold mb-2 mt-4 text-lg">5. Your Choices Regarding Cookies</h3>
            <p className="mb-4">You have control over how cookies are used on your device. Here are your options:</p>
            <p className="pl-4 mb-4 bold">a. Managing Cookies Through Your Browser</p>
            <p className="pl-4 mb-4">Most web browsers allow you to control cookies through their settings. You can:</p>
            <ul className="pl-8 mb-4 list-disc list-inside" style={{ listStyle: 'disc' }}>
              <li><span className="bold">View Cookies</span>: See which cookies are stored on your device.</li>
              <li><span className="bold">Delete Cookies</span>: Remove specific cookies or all cookies.</li>
              <li><span className="bold">Block Cookies</span>: Prevent cookies from being stored on your device.</li>
            </ul>
            <p className="pl-4 mb-4 bold">How to Manage Cookies:</p>
            <ul className="pl-8 mb-4 list-disc list-inside" style={{ listStyle: 'disc' }}>
              <li>
                <span className="bold">Google Chrome</span>: <a href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank" rel="noopener noreferrer" style={{ color: '#15c', textDecoration: 'underline' }}>Manage Cookies</a>
              </li>
              <li>
                <span className="bold">Mozilla Firefox</span>: <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target="_blank" rel="noopener noreferrer" style={{ color: '#15c', textDecoration: 'underline' }}>Manage Cookies</a>
              </li>
              <li>
                <span className="bold">Microsoft Edge</span>: <a href="https://support.microsoft.com/en-us/windows/manage-cookies-in-microsoft-edge-view-allow-block-delete-and-use-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank" rel="noopener noreferrer" style={{ color: '#15c', textDecoration: 'underline' }}>Manage Cookies</a>
              </li>
              <li>
                <span className="bold">Apple Safari</span>: <a href="https://support.apple.com/en-us/HT201265" target="_blank" rel="noopener noreferrer" style={{ color: '#15c', textDecoration: 'underline' }}>Manage Cookies</a>
              </li>
            </ul>

            <p className="pl-4 mb-4 bold">b. Opting Out of Targeted Advertising</p>
            <p className="pl-4 mb-4">
              You can opt out of targeted advertising by adjusting your device settings or using industry opt-out tools:
            </p>
            <ul className="pl-8 mb-4 list-disc list-inside" style={{ listStyle: 'disc' }}>
              <li>
                <span className='bold'>Digital Advertising Alliance (DAA)</span>: Opt-Out
              </li>
              <li>
                <span className='bold'>Network Advertising Initiative (NAI)</span>: Opt-Out
              </li>
              <li>
                <span className='bold'>European Interactive Digital Advertising Alliance (EDAA)</span>:{' '}
                <a
                  href="http://www.youronlinechoices.eu/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#15c', textDecoration: 'underline' }}
                >
                  Opt-Out
                </a>
              </li>
            </ul>

            <p className="pl-4 mb-4 bold">c. Using Incognito/Private Browsing Modes</p>
            <p className="pl-4 mb-4">
              Using incognito or private browsing modes can limit the storage of cookies on your device. However, this may affect the
              functionality and personalization of our Website.
            </p>

            <h3 className="font-bold mb-2 mt-4 text-lg">6. Changes to This Cookies Policy</h3>
            <p className="mb-4">
              We may update our Cookies Policy from time to time. Any changes will be posted on this page, and the “Last Updated” date will
              be revised accordingly. We encourage you to review this policy periodically to stay informed about our use of cookies.
            </p>

            <h3 className="font-bold mb-2 mt-4 text-lg">7. Contact Us</h3>
            <p className="mb-4">
              If you have any questions or concerns about our Cookies Policy or our use of cookies, please contact us at:
            </p>
            <ul className="pl-8 mb-4 list-disc list-inside" style={{ listStyle: 'disc' }}>
              <li><span className='bold'>Email:</span> help@reok.pro</li>
            </ul>
            <p className="mb-4">
              By continuing to use our Website, you agree to our use of cookies as described in this Cookies Policy.
            </p>
          </div>
        </div>
      </section>
      {/* <section>
        <div className="relative bg-neutral-black">
          <div className="flex flex-col align-items-center section-top-90">
            <BeforeAfterTestimonial />
            <div className="getstarted-btn-wrapper mt-4">
              <Link to={currentLanguage === 'en' ? '/signup' : `/${currentLanguage}/signup`} className="upgrade-button mt-4">
                {t('button.try')}
                <span style={{ marginLeft: '4px' }}>→</span>
              </Link>
            </div>
            <div className="text-white mt-4">
              <LovedBy t={t} />
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Terms;
