import React from 'react';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import FlashAutoIcon from '@mui/icons-material/FlashAuto';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

const LandingPerfectHeadshot = () => {
  const features = [
    {
      title: 'Tailored to You',
      highlighted: true,
    },
    {
      title: 'Fast & Efficient',
      highlighted: true,
    },
    {
      title: 'Shoot any style and pose',
      highlighted: true,
    },
    {
      title: 'Copy any concept you like',
      highlighted: true,
    },
    // {
    //   title: 'Get high-resolution photos',
    //   highlighted: true,
    // },
    {
      title: 'Edit your photos by AI-powered tools',
      highlighted: true,
    },
    {
      title: 'Save your time and money effortlessly',
      highlighted: true,
    },
  ];

  const renderFeature = ({ title, description, highlighted }) => (
    <React.Fragment key={title}>
    <li className={`price-card--features--item ${highlighted ? 'text-white' : ''} flex flex-col items-center text-center`}>
      <span className="green-check mb-2">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
            fill="#71ff9a"
          />
        </svg>
        {title}
      </span>
    </li>
  </React.Fragment>
  );

  return (
    <section 
      className="max-w-4xl mx-auto p-4 rounded-lg shadow-lg mb-10 bg-cover bg-center bg-black"
      // style={{ backgroundImage: "url('./images/bg-why-ai-studio.png')" }}
    >
      <div className="flex flex-col items-center bg-opacity-50 p-4 rounded-lg">
        <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 w-full">
          {/* {features.map(renderFeature)} */}
          <li className={`price-card--features--item text-white flex flex-col items-center text-center`}>
            <div className="green-check mb-2">
              <SensorOccupiedIcon sx={{ color: '#fc5185' }}/>
              <span className="pl-2">Tailored to You</span>
            </div>
          </li>
          <li className={`price-card--features--item text-white flex flex-col items-center text-center`}>
           <div className="green-check mb-2">
              <FlashAutoIcon sx={{ color: '#fcd571' }}/>
              <span className="pl-2">Fast & Efficient</span>
            </div>
          </li>
          <li className={`price-card--features--item text-white flex flex-col items-center text-center`}>
           <div className="green-check mb-2">
              <EmojiPeopleIcon sx={{ color: '#57daac' }}/>
              <span className="pl-2">Shoot any style and pose</span>
            </div>
          </li>
          <li className={`price-card--features--item text-white flex flex-col items-center text-center`}>
           <div className="green-check mb-2">
              <CopyAllIcon sx={{ color: '#32aaf9' }}/>
              <span className="pl-2">Copy any concept you like</span>
            </div>
          </li>
          <li className={`price-card--features--item text-white flex flex-col items-center text-center`}>
           <div className="green-check mb-2">
              <AutoAwesomeIcon sx={{ color: '#afff6d' }}/>
              <span className="pl-2">Edit your photos by AI-powered tools</span>
            </div>
          </li>
          <li className={`price-card--features--item text-white flex flex-col items-center text-center`}>
           <div className="green-check mb-2">
              <EventAvailableIcon sx={{ color: '#b689ff' }}/>
              <span className="pl-2">Save your time and money effortlessly</span>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default LandingPerfectHeadshot;