import React, { useEffect, useState, useRef, useLayoutEffect, memo } from 'react';
import Spinner from '../Spinner';
import ImageResult from '../ImageResult';
import LoaderLineMask from '../LoaderLineMask';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import NewSkeleton from '../NewSkeleton';
import ExportPhoto from '../ExportPhoto';

const MyDesign = memo(({ userData, t, isCreating, firebase, checkCredits, onUpImageResolution, onRemoveBG, onMagicFixer, darkMode, isMobile }) => {
  const scrollableRef = useRef(null);
  const [jobsList, setJobsList] = useState([]);
  const [imageSrcCompare, setImageSrcCompare] = useState({});
  const [isDownloading, setIsDownloading] = useState(false);
  const [showShopFurniture, setShowShopFurniture] = useState(false);
  const [imageShop, setImageShop] = useState(null);
  const [imageSrcEdit, setImageSrcEdit] = useState();
  const [showEditImage, setShowEditImage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lastJobCreatedAt, setLastJobCreatedAt] = useState("");
  const [isSpinning, setIsSpinning] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showExportPhoto, setShowExportPhoto] = useState(false);

  useEffect(() => {
    // setIsSpinning(true);
    firebase.getFirstJobs(firebase.auth.currentUser.uid)
      .then((result) => {
        const jobsArray = result.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

        const updatedJobList = jobsArray.filter(job => job.isDeleted !== true);
        if (updatedJobList.length > 1) {
          setJobsList(updatedJobList);
          setLastJobCreatedAt(updatedJobList[updatedJobList.length - 1].createdAt);
        }
        setTimeout(() => {
          setIsSpinning(false);
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
    return () => {
    };
  }, []);

  useEffect(() => {

    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const pageOffset = window.pageYOffset;
    
      // Check if the user has scrolled to the bottom of the page
      if (windowHeight + pageOffset >= documentHeight - 100) {
        loadMorePhotos(isLoading, jobsList, lastJobCreatedAt);
      }
    };
    
    // Attach the scroll event listener to the window
    window.addEventListener('scroll', handleScroll);
    
    return () => {
      // Remove the scroll event listener when the component unmounts
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isLoading, lastJobCreatedAt, jobsList, scrollableRef]);

  const onLoadMore = () => {
    loadMorePhotos(isLoading, jobsList, lastJobCreatedAt);
  }

  const loadMorePhotos = async (loading, jobs, lastCreatedAt) => {
    if (!loading && lastCreatedAt !== "") {
      setIsLoading(true);
      firebase.getJobsPagination(firebase.auth.currentUser.uid, lastCreatedAt)
        .then((result) => {

          const jobsArray = result.docs
            .filter((doc) => doc.data().mode !== "BASIC")
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

          const updatedJobList = jobsArray.filter(job => job.isDeleted !== true);

          if (updatedJobList.length > 0) {
            setTimeout(() => {
              const mergedJobsList = [...jobs, ...updatedJobList];
              setLastJobCreatedAt(updatedJobList[updatedJobList.length - 1].createdAt);
              setJobsList(mergedJobsList);
              setIsLoading(false);
            }, 2000);
          } else {
            setLastJobCreatedAt("");
            setIsLoading(false);
          }

        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  function generateRandomFileName(extension) {
    const randomNumber = Math.floor(Math.random() * 1000000000); // Generate a random number between 0 and 999999999
    return `reok-${randomNumber}.${extension}`;
  }

  const onCompareImage = (images) => {
    setImageSrcCompare(images)
    // setShowCompareImage(true);
    setShowExportPhoto(true);
  }

  const onFindAgency = (image) => {
    setOpenForm(true);
    setImageShop(image);
    setShowShopFurniture(false);
  }

  const onEditImage = (imageUrl) => {

    if (checkCredits()) {
      setShowEditImage(false);
      return;
    }

    setImageSrcEdit(imageUrl);
    setShowEditImage(true);
  }

  const onCloseImageCompare = () => {
    setShowExportPhoto(false);
  }

  const onCloseDownload = () => {
    setShowExportPhoto(false);
  }

  const onDownloadImage = (image) => {
    // setImageSrcExport(image)
    // setShowExportPhoto(true);
    setIsDownloading(true);

    try {
      setTimeout(async () => {
        const response = await fetch(image);
        const blob = await response.blob();

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        const fileName = generateRandomFileName('jpeg');
        link.download = fileName;
        link.click();

        URL.revokeObjectURL(url);
        setIsDownloading(false);

      }, 2000);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  }

  const onRemoveFromList = (id) => {
    const updatedJobList = jobsList.filter(job => job.id !== id);
    setJobsList(updatedJobList);
  }

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <>
      <div 
        className={`my-design ${darkMode? 'dark-bg' : ''}`}
        ref={scrollableRef}
      >
        <h3 className={`${darkMode? 'text-white' : 'text-black'}`}>{t('myDesign.title')}</h3>
        <p style={{ color: '#8c8c8c', fontSize: '14px', marginTop: "0", marginBottom: "1rem"}}>
          {t('myDesign.scrollTitle')}
        </p>
        <div className="relative rnftg-h-full rnftg-w-full">
          <div className="relative rnftg-h-full dark:rnftg-bg-gray-900">
            <div className="relative rnftg-flex rnftg-flex-col pb-6">
              <div className="relative rnftg-grid rnftg-gap-6 rnftg-grid-flow-row rnftg-grid-cols-2 md:rnftg-grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
                {jobsList.length > 0 &&
                  jobsList.map((job, key) => {
                    return job.upscaleUrl === "" ? (
                      <article key={key} className="relative rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="relative rnftg-item__img-wrapper">
                        <div className="result" style={{border: darkMode ? "1px solid #292524" : "1px solid #fff"}} >
                          <LoaderLineMask status={0}/>
                          <span className="rnftg-no-underline rnftg-text-black dark:rnftg-text-gray-200">
                            {!imageLoaded && (
                              <NewSkeleton/>
                              )
                            }
                            {job.originalUrl !== "" &&
                              <img
                                className={`rnftg-w-full rnftg-h-full rnftg-object-cover rnftg-cursor-pointer ${imageLoaded ? 'rendering-mask' : ''}`}
                                alt="reok"
                                src={job.originalUrl}
                                onLoad={handleImageLoad}
                                />
                              }
                            <div className="img_src_loader"></div>
                            <div className="prompt-label">{t('message.prompt.rendering')}</div>
                          </span>
                        </div>
                      </div>
                    </article>
                    ) : (
                      <ImageResult
                        t={t}
                        firebase={firebase}
                        userData={userData}
                        key={key}
                        job={job}
                        onCompareImage={onCompareImage}
                        onDownloadImage={onDownloadImage}
                        onFindAgency={onFindAgency}
                        onEditImage={onEditImage}
                        isDownloading={isDownloading}
                        onUpImageResolution={onUpImageResolution}
                        onRemoveBG={onRemoveBG}
                        onMagicFixer={onMagicFixer}
                        hideRenew={false}
                        darkMode={darkMode}
                        mode={job.mode}
                      />
                    );
                  })
                  }
              </div>
              {/* {!isLoading &&
                (
                  <div>
                    <span className="relative flex justify-content-center text-gray-500 loading-more-mobile" style={{cursor: "pointer"}} onClick={onLoadMore}>Click to load more 👇</span>
                  </div>
                  )
                } */}
              {isLoading &&
                (
                <div className="mt-10">
                  <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                    <LinearProgress color="inherit" />
                  </Stack>
                </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
      {/* {showCompareImage && <CompareImage images={imageSrcCompare} onClose={onCloseImageCompare}></CompareImage>} */}
      {showExportPhoto && <ExportPhoto onClose={onCloseImageCompare} images={imageSrcCompare} isMobile={isMobile}></ExportPhoto>}
      {/* {showShopFurniture && <ShopFurniture productsList={productsList} firebase={firebase} t={t} image={imageShop} onClose={onCloseShopFurniture}></ShopFurniture>} */}
      {/* {showEditImage && <EditImage checkCredits={checkCredits} imageUrl={imageSrcEdit} onClose={onCloseImageEdit} firebase={firebase} t={t} onGenFill={onGenFill} onChangeImageSrc={onChangeImageSrc} onFetchUser={onFetchUser}></EditImage>} */}
      {/* {searching && <Searching title="Searching products" />} */}
      {/* {openForm && <AgencyForm userData={userData} productsList={productsList} firebase={firebase} t={t} image={imageShop} onClose={onCloseAgencyForm}></AgencyForm>} */}
      {isSpinning && <Spinner />}
    </>
  );
});

export default MyDesign;
